<template>
<b-container>
  <div class="my-5 pt-5">
    <h4 class="gray">
      ลงทะเบียน
    </h4>
    <divider />
    <b-container class="mt-5 pt-4">
      <div class="card h-100 col-lg-5">
        <div class="card-body">
          <router-link to="/createuser" class="link">
            <img
              class="col"
              src="@/assets/svg/Group-7019.svg"
            />
            <div class="col title">
              <p>
                ผู้สมัครงาน
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="card h-100 col-lg-5">
        <div class="card-body">
          <router-link to="/createcompany" class="link">
            <img
              class="col"
              src="@/assets/svg/Group-6971.svg"
            />
            <div class="col title">
              <p>
                ผู้ประกอบการ
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <p class="gray text-center m-2">หากท่านเคยลงทะเบียนไว้แล้ว กดที่ปุ่ม <a href="/login" class="link-login text-bolder">เข้าสู่ระบบ</a></p>
    </b-container>
  </div>
</b-container>
</template>
<script>
import Divider from "@/components/Divider";
export default {
  components: {
    Divider
  }
}
</script>
<style lang="scss" scoped>
  .card {
    color: #5C5C5C;
    margin: 2rem auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    opacity: 1;
    .card-body {
      padding: 0;
    }
  }
  .title {
    display: flex;
    padding: 0;
    p {
      margin: auto;
      font-weight: 100;
      color: #5C5C5C;
      font-size: 2rem;
    }
  }
  a.link {
    display: flex;
    padding: 1rem 0;
    &:hover {
      text-decoration: none;
      color: $primary-hard-color;
    }
  }

  a.link-login {
    color: $theme-color-1;
  }
</style>